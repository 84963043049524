body {
    margin: 0px;
    font-family: 'Open Sans';
    font-style: normal;
}

nav {
    background-color: #000000;
    color: white;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.nav--logo {
    margin: 0 20px;
    display: flex;
    align-items: center;
}

.nav--logo > img {
    max-width: 42px;
    border-radius: 21px;
    margin-right: 10px;
}

.nav--logo > h1 {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.02em;
}

.nav--list {
    margin: auto;
}

.nav--list > a {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.01em;

    padding: 5px 15px;
    border-radius: 25px;
    background-color: black;
    margin: 0px 45px;   
    color: white;
    text-decoration: none;
}

.nav--list > a:hover, 
#nav--list--select-btn {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.01em;
    
    padding: 5px 15px;
    border-radius: 25px;
    background: #03989E;
    margin: 0px 45px;
    color: white;
    text-decoration: none;
}

#table {
    margin: 0 100px;
}

#table > iframe {
    width: 90%;
    height: 300px;
}

.title {
    max-width : 100%;
    display: flex;
    align-items: center;
    padding: 30px;
}

.title > h1 {
    
    width: auto;
    height: 75px;
    
    margin: 0 20px;
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 75px;

    color: #000000;
}

.title > select {
    margin: 0 40px;

    width: 356px;
    height: 57px;
    background-color: #C4C4C4;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    border-radius: 5px;

}

.title > select > option {
    width: 289px;
    height: 41px;
    font-size: 18px;
    line-height: 33px;
}

.title > p {
    margin-left:auto;
}

.login-wrapper {
    text-align: center;
}
  
.top-container{
    background-color: black;
    padding: 0.3%;
    margin-bottom: 100px;
}
.top-container-signup{
    background-color: black;
    padding: 0.3%;
}
.top-container h1 {
    color: white;
    font-size: 30px;
}
.top-container-signup h1 {
    color: white;
    font-size: 30px;
}
.middle1{
    margin-bottom: 4%;
}
.middle1 h1{
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
}
.middle2{
    margin-bottom: 3%;
}
input{
    background-color: #EFEFEF;
    border-radius: 5px ;
    border-width: 0;
    padding-left: 1%;
    padding-right: 1%;
    width: 400px;
    height: 55px;
    text-align: center;
    font-size: 24px;
}
::placeholder{
    color:#03989E;
    text-align: center;
}

input[type="submit"]{
    background-color: #03989E;
    border-radius: 5px;
    color: #FFFFFF;
    width: 300px;
    height: 55px;
    font-weight: 700;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
}

input[type="submit"]::placeholder {
    color: white;
}

.scrollit {
    overflow-y: scroll;
    height: 400px;
}

.table-body {
    margin: 0;
    padding: 0;
}

table, th, td {
    text-align: center;
    padding: 15px 30px;
}

th, td {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.table-header {
    background: white;
    font-weight: 300;
}

.table-content {
    background: #F2F2F2;
}

.cs-wrapper {
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
}

.csform {
    text-align: left;
    padding-left: 100px;
    margin-bottom: 3%;
    font-size: 20px;
}

.csform > input{
    background-color: #EFEFEF;
    border-radius: 5px ;
    border-width: 0;
    padding-left: 1%;
    padding-right: 1%;
    width: 400px;
    height: 45px;
    text-align: center;
    font-size: 25px;
    font-family: 'Open Sans';
    font-style: normal;
}

.csbottom{
    color:#03989E;
    padding-left: 100px;
    text-align: left;
}

.csbottom > input{
    color:#FFFFFF;
    text-align: center;
    border-radius: 5px ;
    border-width: 0;
    padding-left: 1%;
    padding-right: 1%;
    width: 420px;
    height: 45px;
    font-size: 25px;
}

.cs_add > a{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.01em;
    
    padding: 10px 20px;
    border-radius: 25px;
    background: #03989E;
    margin: 0px 45px;
    color: white;
    text-decoration: none;
}
.dashboard {
    display: flex;
}

.dash-button-container {
    margin: 10px;
}

.dashboard-button {
    width: 200px;
    padding: 10px;
    background-color: #F2F2F2;
    border-radius: 15px;
    margin-left: 50px;
    margin-bottom: 5px;
}

.dashboard-button:hover, .dashboard-button-selected {
    width: 200px;
    padding: 10px;
    background-color: #D1D1D1;
    border-radius: 15px;
    margin-left: 50px;
    margin-bottom: 5px;
}

.approval {
    display: flex;
}

.approval-button-container {
    margin: 10px;
}

.approval-button {
    width: 200px;
    padding: 10px;
    background-color: #F2F2F2;
    border-radius: 15px;
    margin-left: 50px;
    margin-bottom: 5px;
}

.approval-button:hover, .approval-button-selected {
    width: 200px;
    padding: 10px;
    background-color: #D1D1D1;
    border-radius: 15px;
    margin-left: 50px;
    margin-bottom: 5px;
}